const { DateTime } = require("luxon")
const { TEXT_LANG } = require("../lang/main")

const hoy = DateTime.now().toUTC().toISO()

const calcularVolumenGlobal = (ancho, alto, largo, unidad) => {
    let volumen = ancho * alto * largo; // Calcula el volumen en la unidad de medida original
  
    switch (unidad) {
      case "MTR":
        // El volumen ya está en metros cúbicos, no se necesita conversión
        return { volumen, unidadVolumen: "m³" };
      case "INCH":
        // Convertir a pulgadas cúbicas: 1 m³ = 61023.7 in³
        volumen = volumen * 61023.7;
        return { volumen, unidadVolumen: "in³" };
      case "CM":
        // Convertir a centímetros cúbicos: 1 m³ = 1,000,000 cm³
        volumen = volumen * 1000000;
        return { volumen, unidadVolumen: "cm³" };
      case "SQFT":
      case "SQM":
        // SQFT y SQM son unidades de área y no se pueden convertir directamente a volumen sin una dimensión adicional
        return { volumen: 0, unidadVolumen: "no aplicable (SQFT y SQM son unidades de área)" };
      default:
        // En caso de que se proporcione una unidad no soportada
        return { volumen: 0, unidadVolumen: "unidad no soportada" };
    }
}


const tipos_tiempo_entrega = [
    { slug: "a_tiempo", query: {
        $or: [
            { fecha_finalizado: { $nin: [null] }, $expr: {
                $gte: ["$fecha_entrega_hasta", "$fecha_finalizado"]
            } },
            { fecha_retiro_hasta: { $nin: [null], $gte: hoy } },
        ]
    } },
    { slug: "retrasadas_entregadas", query: { fecha_finalizado: { $nin: [null] }, $expr: {
        $lte: ["$fecha_entrega_hasta", "$fecha_finalizado"]
    } } },
    { slug: "retrasadas_pendientes", query: { fecha_finalizado: { $in: [null] }, fecha_entrega_hasta: { $lte: hoy } } },
    { slug: "retrasadas_devueltas", query: { fecha_devuelto: { $nin: [null] }, $expr: {
        $lte: ["$fecha_entrega_hasta", "$fecha_devuelto"]
    } } }
]

const formatoMoneda = value => {
    if(isNaN(value)) return "Sin información"
    return Intl.NumberFormat("en-DE").format(Math.round(value))
}

const sortDynamic = (key, order = 'asc') => {
    const sortOrder = order === 'asc' ? 1 : -1
    return (a, b) => {
        const A = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const B = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
        if ( A < B ){
            return sortOrder * -1
        }else if ( A > B ) {
            return sortOrder * 1
        } else {
            return 0
        }
    }
}

const obtenerTiemposEntregaOpciones = (pais_code) => {

    const ids = tipos_tiempo_entrega.map(e => e.slug)

    const data = ids.map(op => ({ value: op, label: TEXT_LANG(op, pais_code) })) 

    return data
}

const obtenerQueryTiempoEntrega = (slug) => {
    const i = tipos_tiempo_entrega.findIndex(t => t.slug === slug)
    if(i < 0) return false
    return tipos_tiempo_entrega[i].query
}

const extraerNumeros = (str) => {
    const resultado = str.match(/\d+/g) || []
    return resultado.join('')
}

const calcularVolumen = (pro, u_m) => {
            let um = u_m ? u_m : 'CM'
            let ancho       = 0
            let alto        = 0
            let largo       = 0

            if(!isNaN( parseFloat(pro.ancho) ))     ancho   = parseFloat(pro.ancho)
            if(!isNaN( parseFloat(pro.alto) ))      alto    = parseFloat(pro.alto)
            if(!isNaN( parseFloat(pro.largo) ))     largo   = parseFloat(pro.largo)

            const total = calcularVolumenGlobal(ancho, alto, largo, um)
            return total
}

const stringByStatus = (status) => {
    switch (status) {
        case "active":
            return "Activo"
        default:
            return "Inactivo"
    }
}

const encontrar_elemento = (iddiv) => {
    const geocoderAddressDiv = document.getElementById(iddiv);

    // Verifica si se encontró el elemento con el id
    if (geocoderAddressDiv) {
    // Busca el elemento con la clase "mapboxgl-ctrl-geocoder" dentro del elemento capturado
    const mapboxGeocoderDiv = geocoderAddressDiv.querySelector('.mapboxgl-ctrl-geocoder');

    // Verifica si se encontró el elemento con la clase dentro del elemento capturado
    if (mapboxGeocoderDiv) {
        // Haz algo con el elemento, por ejemplo, modificar su contenido o estilo
        return true
    } else {
        return false
    }
    } else {
        return false
    }
}

const obtenerLimitesPagina = (items, key, interfaz, modulo) => {
    let default_val = 20
    const instancia_esquema = JSON.parse( JSON.stringify(interfaz) )
    if(items) if(Array.isArray(items)) if(items.length > 0){
        if(instancia_esquema) if(instancia_esquema.limit_pagination) if(typeof instancia_esquema.limit_pagination === "object") if(instancia_esquema.limit_pagination[modulo]){
            return instancia_esquema.limit_pagination[modulo][key]
        }
        return items[0].value
    }
    return default_val
}

const obtenerIDS = (valores) => {
    if(!valores) return []
    if(Array.isArray(valores) !== true) return []
    const ids = valores.map(e => e._id).filter(e => e)
    return ids
  }

  const convertirSlug = (texto) => {
    return texto
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Elimina caracteres especiales
    .replace(/\s+/g, '-') // Reemplaza espacios por guiones
    .trim(); // Elimina espacios al inicio y final del texto

  } 
module.exports = {
    formatoMoneda,
    sortDynamic,
    obtenerTiemposEntregaOpciones,
    obtenerQueryTiempoEntrega,
    extraerNumeros,
    calcularVolumen,
    stringByStatus,
    encontrar_elemento,
    obtenerLimitesPagina,
    obtenerIDS,
    convertirSlug
}