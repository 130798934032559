import React, { useEffect, useRef, useState } from "react";
import { Layout, Input, Button, Avatar, List, Typography, message, Form, Row, Col, Badge, Tag } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { url_images, urlapi } from "../../lib/backend/data";
import { FaCheck, FaChevronRight, FaRegClock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { cerrarSesion } from "../../redux/actions/sesion";
import socket from "../../lib/websockets";

const { Footer, Content } = Layout;
const { Text } = Typography;

const ChatLayout = (props) => {
    const {
        id_target
    } = props
    const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [ messagesWaiting, setMessagesWaiting] = useState([])
  const [newMessage, setNewMessage] = useState("");
  const session = useSelector(state => state.miusuario)
  const [ penalizados, setPenalizados ] = useState([])
  const dispatch = useDispatch()
  const sesion = useSelector(state => state.miusuario)
  const [ messageApi, contextHolder] = message.useMessage();
  const messagesEndRef = useRef(null);
  const identificador = `chat_message_${id_target}`
  const identificador_2     = `chat_message_penalizacion_${id_target}`


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const obtenerMensaje = async (payload)=>{
    return fetch(`${urlapi}/chat/message/list`,{
        method:'POST',
        body: JSON.stringify({
            condicion: { id_target }
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            messageApi.error('Sin datos')
        } else if(res.errorMessage){
            messageApi.error(res.errorMessage)
        } else if(res.datos){
          const nuevos = res.datos
          if(messagesWaiting.length > 0){
            for( const message of messagesWaiting){
              const i = nuevos.findIndex(x => x._id === message._id)
              if(i < 0) nuevos.push(message)
            }
            setMessagesWaiting([...[], ...[]])
          }
            setMessages(nuevos)
            setTimeout(() => scrollToBottom(), 200);
        }
        return setLoading(false)
    })
    .catch(error => {
        messageApi.error("Error al consultar la información, intente nuevamente")
        return setLoading(false)
    })
}

const guardarMensaje = async (payload)=>{
    return fetch(`${urlapi}/chat/message`,{
        method:'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
        } else if(res._id){
            setMessages(prev => {
                let actual = [...prev]
                const i = actual.findIndex(x => x.localId === res.localId)
                if(i >= 0) actual[i] = res
                return [...[], ...actual]
            })
        }
    })
    .catch(error => {

    })
}

const penalizarMensaje = (message) => {

  if(message.idSender !== session?.data?._id) return false

  return setPenalizados(prev => {
    let actual = [...prev]
    actual.push(message.localId)
    return [...[], ...actual]
  })
}

  const recibirNuevoMensaje = (message) => {
    console.log(message)
    // if(loading) return setMessagesWaiting(prev => {
    //   let actual = [...prev]
    //   const i = actual.findIndex(x => x._id === message._id)
    //   if(i < 0) actual.push(message)
    //   return [...[], ...actual]
    // })

    setMessages(prev => {
        let actual = [...prev]
        const i = actual.findIndex(x => x.localId === message.localId)
        if(i < 0) {
          actual.push(message)
          setTimeout(() => scrollToBottom(), 100);
        }
        return [...[], ...actual]
    })
  }

    useEffect(() => {
        obtenerMensaje()
        socket.on(identificador, nuevo => {
          return recibirNuevoMensaje(nuevo)
        })
        socket.on(identificador_2, nuevo => {
          return penalizarMensaje(nuevo)
        })
        return () => {
            socket?.off(identificador)
        }

    }, [])

  const info = [
        {
            message: "El chat está diseñado para que puedas proveer mayor información acerca de tu carga"
        },
        {
            message: "Podrás proveer información relevante para recibir una cotización más acertada"
        },
    ]


  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      setMessages(prev => {
            let actual = [...prev]
            const idUnico = uuidv4()
            const nuevoMensaje = { id_target,  content: newMessage, type_sender: "provider", sender: session?.data?.nombres ? session?.data?.nombres : "Proveedor", localId: idUnico, idSender: session?.data?._id, time: new Date().toLocaleTimeString() }
            guardarMensaje(nuevoMensaje)
            actual.push(nuevoMensaje)
            setTimeout(() => scrollToBottom(), 100);
            return [...[], ...actual]
      })
      setNewMessage("");
    }
  };

  const textoSegunTipoUsuario = (tipo) => {
    if(!tipo) return "cliente"
    switch (tipo.toString().toLowerCase()) {
      case "provider":
        return "desde proveedor"
      case "client":
        return "Cliente"
      default:
        return tipo
    }
  }

  const componentChat = <div>
    <img style={{ maxWidth: 100}} src={`${url_images}/animations/Chat.gif`} />
            <h4>Bienvenido al chat</h4>
            <p className="mb-3">Antes de continuar te dejamos algunos tips para tu información</p>
            <ul className="mb-3">
                {
                    info.map((item, index) => {
                        return <li key={`item-${index}`}><b><FaChevronRight /></b> {item.message}</li>
                    })
                }
            </ul>
  </div>

  return (
    <Layout style={{ height: 500, border: "1px solid #f0f0f0" }}>
      {/* Content (Messages List) */}
      <Content style={{ padding: 20, overflowY: "scroll" }}>
        <List
          locale={{ emptyText: componentChat }}
          dataSource={messages}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar style={{ backgroundColor: item.idSender === session?.data?._id ? "#1890ff" : "#87d068" }}>{item.sender[0]}</Avatar>}
                title={<Text strong>{item.idSender === session?.data?._id ? "Tú" : <div>{item.sender} <Tag color="black" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: "lighter" }}>{textoSegunTipoUsuario(item.type_sender)}</Tag></div> } </Text>}
                description={<div>
                  <Text style={{ display: "block" }}>{item._id ? <FaCheck /> : <FaRegClock /> } {item.content}</Text>
                  {item.penalizado || penalizados.includes(item.localId) ? <Tag color="red" style={{ fontSize: 10, textTransform: "uppercase" }}>Este mensaje podría infringir nuestras políticas de no compartir datos de contacto</Tag> : false }
                </div>}
              />
              <Text type="secondary" style={{ alignSelf: "center" }}>{item.time} </Text>
            </List.Item>
          )}
        />
        <div ref={messagesEndRef} />
      </Content>

      <Footer style={{ padding: "10px 20px", backgroundColor: "#fff", borderTop: "1px solid #f0f0f0" }}>
        <div style={{ alignItems: "center" }}>
            <Form layout="vertical" onSubmitCapture={handleSendMessage}>
                <Row gutter={10}>
                    <Col md={20}>
                    <Input
                        disabled={loading}
                        rows={2}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Escribe tu mensaje aquí..."
                        style={{ width: "100%", height: 40, marginRight: "10px", borderRadius: "5px" }}
                    />
                    </Col>
                    <Col md={4}>
          <Button disabled={loading} loading={loading} style={{ width: "100%", height: 40, }} type="primary" icon={<SendOutlined />} onClick={handleSendMessage}>ENVIAR</Button>
                    
                    </Col>
                </Row>
          
          </Form>
        </div>
      </Footer>
      {contextHolder}
    </Layout>
  );
};

export default ChatLayout;