const CAMPOS_PERSONALIZADOS = [
    { group: "general", key: "title", label: "Título"},
    { group: "general", key: "tipo", label: "Tipo"},
    { group: "general", key: "slug", label: "Slug"},
    { group: "general", key: "createdAt", label: "Fecha creación"},
]

const CATEGORIAS = [
    { group: "general", key: "title", label: "Título"},
]

const SUBCATEGORIAS = [
    { group: "general", key: "title", label: "Título"},
    { group: "general", key: "id_categoria", label: "Categoría"},
]

const CLIENTES = [
    { group: "general", key: "razon_social", label: "Razón social"},
    { group: "general", key: "nombre_comercial", label: "Nombre comercial"},
    { group: "general", key: "rut", label: "ID Fiscal"},
    { group: "general", key: "direccion", label: "Dirección"},
    { group: "general", key: "ciudad", label: "Ciudad"},
    { group: "general", key: "estado", label: "Estado"},
    { group: "general", key: "localidad", label: "Localidad"},
    { group: "general", key: "pais", label: "País"},
    { group: "general", key: "web", label: "Web"},
    { group: "general", key: "moviles_adicionales", label: "Teléfonos"},
    { group: "general", key: "emails_adicionales", label: "Emails"},
    { group: "general", key: "createdAt", label: "Fecha de creación"},
]

const BODEGAS = [
    { group: "general", key: "titulo", label: "Título"},
    { group: "general", key: "direccion", label: "Dirección"},
    { group: "general", key: "ciudad", label: "Ciudad"},
    { group: "general", key: "pais", label: "País"},
    { group: "general", key: "encargado", label: "Contacto"},
    { group: "general", key: "cargo", label: "Cargo contacto"},
    { group: "general", key: "email_encargado", label: "Email contacto"},
    { group: "general", key: "movil_encargado", label: "Móvil contacto"},
    { group: "general", key: "horarios", label: "Horarios"},
    { group: "general", key: "cliente", label: "Cliente"},
]

const CONTACTOS = [
    { group: "general", key: "nombres", label: "Nombres"},
    // { group: "general", key: "apellidos", label: "Apellidos"},
    { group: "general", key: "status", label: "Estado"},
    { group: "general", key: "email", label: "Email principal"},
    { group: "general", key: "cliente", label: "Empresa"},
    { group: "general", key: "movil", label: "Móvil principal"},
    { group: "general", key: "moviles_adicionales", label: "Teléfonos adicionales"},
    { group: "general", key: "emails_adicionales", label: "Emails adicionales"},
    { group: "general", key: "createdAt", label: "Fecha de creación"},
    { group: "general", key: "pais", label: "País"},
    { group: "general", key: "direccion", label: "Dirección"},
    { group: "general", key: "ciudad", label: "Ciudad"},
    { group: "general", key: "cargo", label: "Cargo"},
    { group: "general", key: "area", label: "Área"},
    { group: "general", key: "creador", label: "Creador"}
]

const EQUIPOS = [
    { group: "general", key: "titulo", label: "Título"},
    { group: "general", key: "patente", label: "Patente"},
    { group: "general", key: "categoria", label: "Categoría"},
    { group: "general", key: "sub_categoria", label: "Sub categoría"},
    { group: "general", key: "medidas_equipo", label: "Medidas unitarias"},
    { group: "general", key: "marca", label: "Marca"},
    { group: "general", key: "modelo", label: "Modelo"},
    { group: "general", key: "id_tipomotor", label: "Tipo de motor"},
    { group: "general", key: "ano", label: "Año"},
    { group: "general", key: "ejes", label: "Ejes"},
    { group: "general", key: "peso", label: "Peso máx. de carga"},
    { group: "general", key: "creador", label: "Creador"},
    { group: "general", key: "volumen", label: "Volumen total"},
    { group: "general", key: "medidas", label: "Capacidad"},
    { group: "general", key: "pallets", label: "Pallets"},
    { group: "general", key: "peso_bruto", label: "Peso bruto"},
    { group: "general", key: "estado", label: "Estado"},
    { group: "general", key: "createdAt", label: "Fecha de registro"},
]

const VEHICULOS = [
    { group: "general", key: "patente", label: "Patente" },
    { group: "general", key: "conductor", label: "Conductor" },
    { group: "general", key: "modelo", label: "Modelo" },
    { group: "general", key: "marca", label: "Marca" },
    { group: "general", key: "ultima_asignacion", label: "Última asignación" },
    { group: "general", key: "ano", label: "Año" },
    { group: "general", key: "tipo", label: "Tipo" },
    { group: "general", key: "tipo_pallet", label: "Tipo de pallet" },
    { group: "general", key: "cantidad_pallet", label: "Cantidad pallet" },
    { group: "general", key: "tipo_motor", label: "Tipo de motor" },
    { group: "general", key: "volumen", label: "Volumen total" },
    { group: "general", key: "peso", label: "Peso máx. de carga" },
    { group: "general", key: "capacidad", label: "Capacidad" },
    { group: "general", key: "creador", label: "Creador" },
    { group: "general", key: "status", label: "Estado" },
    { group: "general", key: "createdAt", label: "Fecha de creación"},
]

const CONDUCTORES = [
    { group: "general", key: "nombres", label: "Nombres"},
    { group: "general", key: "imagen_perfil", label: "Imagen"},
    { group: "general", key: "rut", label: "Rut"},
    { group: "general", key: "email", label: "Email"},
    { group: "general", key: "phone", label: "Móvil"},
    { group: "general", key: "vehiculo", label: "Vehículo"},
    { group: "general", key: "requisitos", label: "Aprobación de requisitos"},
    { group: "general", key: "alertas", label: "Geo Alertas"},
    { group: "general", key: "createdAt", label: "Fecha creación"},
    { group: "general", key: "status", label: "Estado"},
    { group: "general", key: "listReq", label: "Requisitos"},
    { group: "general", key: "listVehiculos", label: "Vehiculos"},
    { group: "general", key: "listPersonalizada", label: "Personalizado"},
]

const VIAJES = [
    { group: "general", key: "id", label: "ID"},
    { group: "fechas", key: "updaedAt", label: "Fecha de última actualización"},
    { group: "general", key: "cumplimiento", label: "Cumplimiento"},
    { group: "general", key: "estado", label: "Estado App"},
    { group: "general", key: "identificador", label: "Identificador"},
    { group: "general", key: "ruta", label: "Ruta"},
    { group: "general", key: "tipo", label: "Tipo de servicio"},
    { group: "general", key: "status", label: "Estado"},
    { group: "general", key: "puntos", label: "Puntos"},
    { group: "general", key: "planificacion", label: "Planificación"},
    { group: "general", key: "cliente", label: "Cliente"},
    { group: "general", key: "productos", label: "Productos"},
    { group: "general", key: "requisitos", label: "Requisitos"},
    { group: "general", key: "documentos", label: "Documentos"},
    { group: "general", key: "costos", label: "Costos"},
    { group: "general", key: "conductor", label: "Conductor"},
    { group: "general", key: "vehiculo", label: "Vehículo"},
    { group: "general", key: "devuelto", label: "Devuelto"},
    { group: "general", key: "finalizado", label: "Finalizado"},
    { group: "general", key: "canal", label: "Canal"},
    { group: "fechas", key: "fecha_retiro", label: "Retiro desde"},
    { group: "fechas", key: "fecha_retiro_hasta", label: "Retiro hasta"},
    { group: "fechas", key: "fecha_entrega", label: "Entrega desde"},
    { group: "fechas", key: "fecha_entrega_hasta", label: "Entrega hasta"},
    { group: "fechas", key: "createdAt", label: "Fecha creación"},
    { group: "fechas", key: "fecha_finalizado", label: "Fecha finalizado"},
    { group: "fechas", key: "fecha_devuelto", label: "Fecha devuelto"},
]

const RUTAS = [
    { group: "general", key: "id", label: "ID"},
    { group: "general", key: "status", label: "Estado"},
    { group: "general", key: "conductor", label: "Conductor"},
    { group: "general", key: "cumplimiento", label: "Cumplimiento"},
    { group: "general", key: "vehiculo", label: "Vehículo"},
    { group: "general", key: "postullable", label: "Postulable"},
    { group: "general", key: "createdAt", label: "Fecha creación"},
]

const PRODUCTOS = [
    { group: "general", key: "descripcion", label: "Descripción"},
    { group: "general", key: "sku", label: "SKU"},
    { group: "general", key: "embalaje", label: "Tipo de embalaje"},
    { group: "general", key: "peso", label: "Peso"},
    { group: "general", key: "unidad_medida_peso", label: "UM Peso"},
    { group: "general", key: "unidad_medida", label: "UM Longitud"},
    { group: "general", key: "ancho", label: "Ancho"},
    { group: "general", key: "alto", label: "Alto"},
    { group: "general", key: "largo", label: "Largo"},
    { group: "general", key: "status", label: "Estado"},
]

const COSTOS = [
    { group: "general", key: "descripcion", label: "Descripción"},
    { group: "general", key: "tipo", label: "Categoría"},
    { group: "general", key: "subtipo", label: "Sub Categoría"},
    { group: "general", key: "tarifa", label: "Monto"},
    { group: "general", key: "tarifa_confirmada", label: "Monto final"},
    { group: "general", key: "id_cliente", label: "Cliente"},
    { group: "general", key: "createdAt", label: "Fecha de creación"},
]

const DISPONIBILIDAD = [
    { group: "general", key: "titulo", label: "Título"},
    { group: "general", key: "tipo_disponibilidad", label: "Tipo"},
    { group: "general", key: "ids_origin", label: "Localidad de carga"},
    { group: "general", key: "ids_destination", label: "Localidad de descarga"},
    { group: "general", key: "carga", label: "Carga"},
    { group: "general", key: "descarga", label: "Descarga"},
    { group: "general", key: "vencimiento", label: "Vencimiento"},
    { group: "general", key: "creador", label: "Creador"},
]

const DISPONIBILIDAD_SOLICITUDES = [
    { group: "general", key: "id", label: "ID"},
    { group: "general", key: "status", label: "Estado"},
    { group: "general", key: "orden", label: "Orden"},
    { group: "general", key: "empresa", label: "Empresa"},
    { group: "general", key: "ids_origin", label: "Localidad de carga"},
    { group: "general", key: "ids_destination", label: "Localidad de descarga"},
    { group: "general", key: "carga", label: "Carga"},
    { group: "general", key: "descarga", label: "Descarga"},
    { group: "general", key: "creador", label: "Creador"},
]

const CALIFICACIONES = [
    { group: "general", key: "estrellas", label: "Calificación"},
    { group: "general", key: "comentario", label: "Comentario"},
    { group: "general", key: "detalles", label: "Detalles"},
    { group: "general", key: "empresa", label: "Empresa transportadora"},
    { group: "general", key: "creador", label: "Creador"},
]

const CANALES = [
    { group: "general", key: "titulo", label: "Título"},
    { group: "general", key: "asignar_app", label: "Asignar app"},
    { group: "general", key: "crear_ruta", label: "Crear ruta"},
    { group: "general", key: "creador", label: "Creador"},
    { group: "general", key: "createdAt", label: "Fecha de creación"}
]

export const Esquema = {
    CAMPOS_PERSONALIZADOS,
    CATEGORIAS,
    SUBCATEGORIAS,
    CLIENTES,
    BODEGAS,
    CONTACTOS,
    EQUIPOS,
    VEHICULOS,
    CONDUCTORES,
    VIAJES,
    RUTAS,
    PRODUCTOS,
    COSTOS,
    DISPONIBILIDAD,
    DISPONIBILIDAD_SOLICITUDES,
    CALIFICACIONES,
    CANALES
}